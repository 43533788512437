import React from "react";
import { useState, useEffect, useRef } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

export const YearPicker = (props) => {
  const { label, includeCodings } = props;
  const [value, setValue] = useState(props.default);
  const [loaded, setLoaded] = useState(false);
  const optionsRef = useRef([]);

  useEffect(() => {
    optionsRef.current = generateYearOptions();
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Codes come from Database
  // Limited to last 100 years
  const generateYearOptions = () => {
    let options = [];
    if (includeCodings) {
      options.push({display: "Unknown", value: 0});
      options.push({display: "Expected", value: 1});
      options.push({display: "Current Student", value: 2});
    }

    const currentYear = new Date().getFullYear();
    const upcomingYear = currentYear + 1;
    for (let i=0;i<100;i++) {
      options.push({display: upcomingYear-i, value: upcomingYear-i});
    }
    
    return options;
  }

  const onChangeYear = (event) => {
    setValue(+event.target.value);
    props.onChange(+event.target.value);
  } 

  return (loaded && 
<FormControl>
      <InputLabel htmlFor="year-native-helper">{label}</InputLabel>
      <NativeSelect
      required
      error={props.error?.length > 0}
      value={value}
      inputProps={{
        name: 'year',
        id: 'year-native-helper',
      }}
      onChange={onChangeYear}>
      {optionsRef.current.map((option, key) => (
          <option key={key} value={option.value}>
              {option.display}
          </option>
      ))}
      </NativeSelect>
    </FormControl>
  );
};

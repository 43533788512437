import '../styles/About.css';

import React from 'react'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography } from '@material-ui/core';

export const AboutProfileView = ({ data }) => {
    return (
        <Card elevation={4} style={{ textAlign: 'center' }}>
            <CardActionArea target="_blank" href={data.link}>
                <CardMedia
                    component="img"
                    height="300"
                    image={data.photo}
                    title=""
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {data.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {data.desc}
                    </Typography>
                </CardContent>
            </CardActionArea>

        </Card>
    )
}
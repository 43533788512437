import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

import useReportData from "../hooks/useReportData";
import { UserContext } from "../UserContext";
import { GradGrid } from "../components/GradGrid";

export const Reports = () => {
  const { user } = useContext(UserContext);
  const { data, status } = useReportData(user);
  let navigate = useNavigate();

  return (
    <>
      {status === "success" && (
        <>
          <Typography align="center" variant="h4">
            {data.institution}
          </Typography>
          <GradGrid user={user}/>
        </>
      )}
      {status === "loading" && <div>Loading</div>}
      {status === "error" && navigate("/")}
    </>
  );
};

import styles from '../styles/About.css';

import React from "react";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { AboutProfileView } from "../components/AboutProfileView";

const membs = require("../members.json");

export const About = () => {
  return (
    <div className={styles.about}>
      <h1>About APDA</h1>
      <div className={styles.description}>
        <p>
        Academic Philosophy Data and Analysis (APDA) is an ongoing project with several current contributors and a Board of Advisors, listed below. 
        The primary purpose of the project is to collect, analyze, and distribute data on job placement for graduates of PhD programs in philosophy. 
        Between 2014 and 2019, the project was funded by the American Philosophical Association. 
        Current funding is provided by Center for the Humanities at University of California, Merced.
        </p>
        <p>On this website, you will find publicly available information for all of our placement entries, organized by graduating program, graduation year, and anonymized individual. 
          You will also find this information available through multiple interactive data tools on the home page.
        </p>
        <p>
        The project aims to use the above entries to discover placement trends. Past reports from our project include the following:
        </p>
        <ul>
            <li><a href="https://www.dropbox.com/s/z9k2ejc7q0zdw9d/APDAFinalReport2015.pdf?dl=0" target="_blank" rel="noopener noreferrer">2015 (project setup details)</a></li>
            <li><a href="https://www.dropbox.com/s/jv3x2n1muzmn2ga/APDAReport2016.pdf?dl=0" target="_blank" rel="noopener noreferrer">2015 (update, first analyses)</a></li>
            <li><a href="https://www.dropbox.com/s/eppppbbjeh1vlp0/APDAFinalReport2016.pdf?dl=0" target="_blank" rel="noopener noreferrer">2016 (first survey)</a></li>
            <li><a href="https://www.dropbox.com/s/61qgeway2nyhr7x/APDA2017FinalReport.pdf?dl=0" target="_blank" rel="noopener noreferrer">2017 (nonacademic focus)</a></li>
            <li><a href="https://www.dropbox.com/s/exv5szay8t6c3o3/diversity-inclusivity-survey.pdf?dl=0" target="_blank" rel="noopener noreferrer">2018 (diversity focus, preliminary)</a></li>
            <li><a href="https://www.dropbox.com/s/iqo388oa9e78xo6/APDA%20Final%20Report%202019.pdf?dl=0" target="_blank" rel="noopener noreferrer">2019 (diversity focus, final)</a></li>
            <li><a href="https://onlinelibrary.wiley.com/doi/epdf/10.1111/meta.12527" target="_blank" rel="noopener noreferrer">2022 (nonacademic focus; published in Metaphilosophy)</a></li>
          </ul>
        <p>
        This project was approved as exempt by the IRB at UC Merced (UCM15-0033).
          You can find a consent form <a href="https://www.dropbox.com/s/dbgprlutkq3yi5a/NewConsentForm.pdf?dl=0" target="_blank" rel="noopener noreferrer">here</a> that explains the above and other details of the project, which you can keep for your records.
          You may contact us at <a href="mailto:apda@ucmerced.edu">apda@ucmerced.edu</a>, which is dedicated to this research project and only accessible by project personnel.
        </p>
      </div>
      <br/>
      <h2>Frequently Asked Questions</h2>
      <div className={`${styles.description} ${styles.faq}`}>
        <p>
          - <em>Where do the data come from?</em><br/><br/>
          APDA data come from a variety of sources. Some of these sources are public repositories of information, like department websites and university dissertation records. Other sources are individuals contacted directly by APDA, such as program representatives and individuals in the database.
        </p>
        <p>
          - <em>Who can edit the data?</em><br/><br/>
          Current contributors to the project with ethics training and IRB approval can access and edit all of the data, program representatives can access and edit a subset of the data for graduates of their own program, and individuals in the database can access and edit all of their own personal data (with the exception of survey entries that have been submitted).        </p>
        <p>
          - <em>How complete are the data?</em><br/><br/>
            They appear to be <a href="https://faculty.ucmerced.edu/cjennings3/phildata/SEDnew.png" target="_blank" rel="noopener noreferrer">about as complete</a> as the Survey of Earned Doctorates for programs in the United States for the years 2011-2020, capturing the vast majority of graduates.
        </p>
        <p>
          - <em>How accurate are the data?</em><br/><br/>
          While we don’t have a direct measure for accuracy, the data have been manually checked for every program in the database by at least one research assistant from 2012-2021, in many cases using multiple sources (see Anna Durbin’s <a href="https://faculty.ucmerced.edu/cjennings3/phildata/APDANotes.pdf" target="_blank" rel="noopener noreferrer">data entry notes</a> for details).
        </p>
        <p>
          - <em>How can someone get access to their data?</em><br/><br/>
          By sending an email to <a href="mailto:apda@ucmerced.edu">apda@ucmerced.edu</a>. You will then be sent an individualized login that you can use from then on to access and edit your own data.
        </p>
        <p>
          - <em>How can programs get added to the database?</em><br/><br/>
          By sending a request to <a href="mailto:apda@ucmerced.edu">apda@ucmerced.edu</a>. APDA aims to have complete coverage of all international Anglophone programs in philosophy, but prioritizes those with placement pages and contact information.
        </p>
      </div>
      <br/>
      <Grid container spacing={2}>
        <h2>
          Current Contributors
        </h2>
        <Grid container spacing={3} alignItems="stretch" style={{"paddingBottom":"90px"}}>
          {membs.current.map((memb) => {
            return (
              <Grid key={memb.name} item xs={12} sm ={5} md={4} lg={3} xl={3}>
                <AboutProfileView data={memb} />
              </Grid>
            );
          })}
        </Grid>
        <h2>
        Board of Advisors
        </h2>
        <Grid container spacing={3} style={{"paddingBottom":"90px"}}>
        {membs.board.map((memb) => {
                      return (
                          <Grid key={memb.name} item xs={12} sm={4} md={3} lg={3} xl={3}>
                              <AboutProfileView data={memb} />
                          </Grid>
                      )
                  })}
        </Grid>
        <h2>
        Past Contributors
        </h2>
        <Grid container spacing={3} style={{"paddingBottom":"90px"}}>
        {membs.past.map((memb) => {
                      return (
                          <Grid key={memb.name} item xs={6} md={3}>
                              <Typography><a style={{"color":"lightcoral"}} href={memb.link}>{memb.name}</a></Typography>
                              <Typography>{memb.desc}</Typography>
                          </Grid>
                      )
                  })}
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
export const USER_TYPE = {
    "INVALID": -1,
    "ORGANIZATION": 1,
    "PERSON": 2
}

export const STATUS_CODE = {
    "SUCCESS": 200,
    "UNAUTHORIZED": 401,
    "NOT_FOUND": 404
}
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";
//import useReportData from "../hooks/useReportData";
import { GradGrid } from "../components/GradGrid";
import { USER_TYPE, STATUS_CODE } from "../models/models";
import { InstitutionSummary } from "../components/InstitutionSummary";


export const Institution = () => {
    const { user, setUser } = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);

    let { id } = useParams();
    let { key } = useParams();
    let navigate = useNavigate();

    if (redirect) {
        //navigate("/institution");
    }

    useEffect(() => {

        if (!user) {
            const defaultUser = {
                type: -1,
                Authorization: null
            };
            fetchData();
        }


        async function fetchData() {

            const res = await fetch(`/api/auth-institution/${id}/${key}`);

            if (res.status === STATUS_CODE.UNAUTHORIZED) {
                setUser({
                    type: USER_TYPE.INVALID,
                    Authorization: null
                });
                navigate("/error");
            } else {
                const d = await res.json();
                setUser({ type: USER_TYPE.ORGANIZATION, Authorization: `Bearer ${d.token}` });
                setRedirect(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {(redirect && user && user.Authorization && user.type === USER_TYPE.ORGANIZATION) ?
                <InstitutionSummary user={user} /> :
                <div className="error">
                    You don't have access to this page--please try again and contact us if you have trouble accessing your account.
                </div>
            }
        </>
    );
}

export default Institution;
import './App.css';
import icon from './assets/icon.svg';
import React, { useMemo, useContext, useState, useEffect, Component } from "react";
import {
  CssBaseline,
  IconButton,
  Container,
  Grid,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { QueryClient, QueryClientProvider } from 'react-query'

import { UserContext } from "./UserContext";
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import {
  ThemeProvider,
  makeStyles,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { Blog } from "./views/Blog";
import { About } from "./views/About";
import { Data } from "./views/Data";
import { Home } from "./views/Home";
import { Accounts } from "./views/Accounts";
import { Error } from "./views/Error";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { Institution } from "./views/Institution";
import { Reports } from "./views/Reports";
import { Profile } from "./views/Profile";



function App(props) {
  const { window } = props;

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  


  let theme = createMuiTheme({
    palette: {
      type: "light",

      primary: {
        main: "#001C55",
      },
      secondary: {
        main: "#F4D35E",
      },
      error: {
        main: "#DA4167",
      },
      warning: {
        main: "#CE796B",
      },
      info: {
        main: "#06D6A0",
      },
      success: {
        main: "#03CEA4",
      },
      contrastThreshold: 3,
    },
    typography: {
      fontFamily: ["IBM Plex Sans", "sans-serif"],
    },
  });
  theme = responsiveFontSizes(theme);
  const classes = useStyles(theme);

  const { user, setUser } = useContext(UserContext);


  const li = [
    {
      pathShort: "/",
      path: "/",
      text:"Home",
      key: 1
    },
    {
      pathShort: "/about",
      path: "/about",
      text:"About",
      key: 2
    },
    {
      pathShort: "/data",
      path: "/data",
      text:"Data",
      key: 3
    },
    {
      pathShort: "/blog",
      path: "/blog",
      text:"Blog",
      key: 4
    },
    {
      pathShort: "/accounts",
      path: "/accounts",
      text:"Accounts",
      key: 5,
      isInvisible: true
    },
    {
      pathShort: "/profile",
      path: "/profile/:id/:key",
      text:"Profile",
      key: 6,
      isInvisible: true
    },
    {
      pathShort: "/institution",
      path: "/institution/:id/:key",
      text:"Institution",
      key: 7,
      isInvisible: true
    },
    {
      pathShort: "/error",
      path: "/error",
      text:"Error",
      key: 8,
      isInvisible: true
    },
  ];

  // if (user?.type === 0) {
  //   li.push({
  //     pathShort: "/profile",
  //     path: "/profile",
  //     text: "My Profile",
  //     key: 9,
  //     isRestricted: true
  //   })
  // }

  // if (user?.type === 1) {
  //   li.push({
  //     pathShort: "/institution",
  //     path: "/institution",
  //     text: "My Institution",
  //     key: 10, 
  //     isRestricted: true
  //   })
  // }

  const [selectedKey, setSelectedKey] = useState(() => {
    if (location.pathname.length <= 1) return 1; 
    const key = li.filter(_item => _item.key > 1).find(_subItem => location.pathname.startsWith(_subItem.pathShort))?.key;
    return key ? key : 1; 
  });
    
  const onClickNavItem = (key) => {
    const clicked = li.find(_item => _item.key === key);
    
    navigate(clicked.path)
  }

  useEffect(() => {
    if (location.pathname.length <= 1) {
      setSelectedKey(1);
    } else {
      const key = li.filter(_item => _item.key > 1).find(_subItem => location.pathname.startsWith(_subItem.pathShort))?.key;
      if (key) setSelectedKey(key);
    }
    
  }, [location])

  const navbar = (

    <Grid container alignItems="center" justify="space-between" className="navbar">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => setIsOpen(!isOpen)}
        className={classes.menuButton}>
        <MenuIcon />
      </IconButton>
      
      <Grid>
        <img src={icon} alt="apda" className="icon" hint="Academic Philosophy Data & Analysis" onClick={() => onClickNavItem(1)}/>
      </Grid>
        
      
      <Grid item>
      <ul className={isOpen ? "links show-nav" : "links"}>
              {
                  li.filter(i => !i?.isInvisible).map((objLink) => {
                      //return ( <li className={ selectedKey === objLink.key ? 'selected': '' } key={objLink.key} onClick={() => onClickNavItem(objLink.key)}>{objLink.text}</li> )
                      return ( <MenuItem selectedKey={selectedKey} key={objLink.key} linkKey={objLink.key} text={objLink.text} path={objLink.path} history={navigate}></MenuItem> )
                  })
              }
          </ul>
      </Grid>
    </Grid>
    
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
          {navbar}
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container>
            <Routes>
              {/* <Route exact path="reports" element={<Reports/>}/> */}
              <Route path="profile/:id/:key" element={<Profile/>}/>
              <Route path="institution/:id/:key" element={ <Institution /> }/>
              <Route exact path="about" element={<About/>}/>
              <Route exact path="blog" element={<Blog/>}/>
              <Route path="data" element={<Data/>}>
                <Route path=":institutionId" element={<Data/>}/>
              </Route>
              <Route exact path="accounts" element={<Accounts/>}/>
              <Route exact path="error" element={<Error/>}/>
              <Route exact path="/" element={<Home/>}/>
              <Route path="*" element={<Home/>}/>
            </Routes>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
}

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({

  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function MenuItem (props) {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(props.path)
  }

  return (<li className={ props.selectedKey === props.linkKey ? 'nav-item selected': 'nav-item' } onClick={onClick}>{props.text}</li>);
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


const AppWithRouter = withRouter(App);

const AppContainer = () => {
  const [user, setUser] = useState(null);
  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser]);
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      
      <Router>
        <UserContext.Provider value={providerValue}>
          
          <AppWithRouter />
        </UserContext.Provider>
      </Router>
      
      
    </QueryClientProvider>
  );
};

export default AppContainer;

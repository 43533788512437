
import '../styles/Profile.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormHelperText from '@material-ui/core/FormHelperText';

import { AutoUniversity } from "../components/AutoUniversity";
import { YearPicker } from "../components/YearPicker";
import { PlacementEdit } from "./PlacementEdit";
import { validateEmail } from '../utilities/utilities';
import { USER_TYPE } from '../models/models';


export const ProfileEdit = ({ user, person, onCancelled, onConfirmed }) => {

    const [profile, setProfile] = useState(person);
    const [placementTypes, setPlacementTypes] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [helperText, setHelperText] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [newPerson] = useState(person.id ? false : true)

    const initForm = {
        id: profile.id,
        email: profile.email ? profile.email : '',
        first_name: profile.first_name ? profile.first_name : '',
        last_name: profile.last_name ? profile.last_name : '',
        institution_id: profile.institution_id,
        institution: profile.institution ? profile.institution : '',
        graduation_year: profile.graduation_year !== undefined ? profile.graduation_year : new Date().getFullYear(),
        notes: profile.notes ? profile.notes : '',
        placements: profile.placements.map((p, idx) => {
            return {
                idx: idx,
                id: p.id,
                institution: p.institution,
                institution_id: p.institution_id,
                placement_type_id: p.placement_type_id,
                placement_type_name: p.placement_type_name,
                position_id: p.position_id,
                position_category: p.position_category,
                year: p.year,
                error: ''
            }
        })
    };

    const [formValues, setFormValues] = useState(initForm);

    const getAreasOfStudy = () => {
        return axios.get('/api/aoscat');
    }

    const getPlacementTypes = async () => {
        return axios.get('/api/placement-types');
    }



    useEffect(() => {

        Promise.all([getAreasOfStudy(), getPlacementTypes()])
            .then((results) => {
                const aosData = results[0]?.data;

                setProfile(state => ({
                    ...state,
                    areasOfStudy: aosData
                }));
                setFormValues(state => ({
                    ...state,
                    aos_category_id: profile.aos_category_id,
                    aos_category: aosData.find(aos => aos.category_id === profile.aos_category_id)?.category,
                    primary_aos_id: profile.primary_aos_id,
                    primary_aos: aosData.find(aos => aos.category_id === profile.aos_category_id).Areas.find(category => category.id === profile.primary_aos_id)?.area
                }));


                const placementTypesData = results[1]?.data;
                setPlacementTypes(placementTypesData);

                formValues.placements.map((placement) => {
                    placement.error = ''
                });
                setFormValues(state => ({
                    ...state,
                    error: {
                        email: profile.email ? '' : 'Please enter an email address',
                        institution_id: '',
                        graduation_year: '',
                        last_name: profile.last_name ? '' : 'Please enter a last name'
                    },
                    placements: formValues.placements ? formValues.placements : [],
                    formerPlacements: []
                }))

                setIsLoaded(true);

            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile])

    const handleChangeEmail = (event) => {
        const error = formValues.error;

        if (validateEmail(event.target.value))
            error.email = '';
        else error.email = 'Please provide a valid email address'


        setFormValues(state => ({
            ...state,
            email: event.target.value,
            error: error
        }));

    };

    const handleChangeFirstName = (event) => {
        setFormValues(state => ({
            ...state,
            first_name: event.target.value
        }));
    }

    const handleChangeLastName = (event) => {
        const error = formValues.error;

        if (event.target.value.length > 0)
            error.last_name = '';
        else error.last_name = 'Please provide a valid last name';
        setFormValues(state => ({
            ...state,
            last_name: event.target.value,
            error: error
        }));
    }

    const handleChangeNotes = (event) => {
        setFormValues(state => ({
            ...state,
            notes: event.target.value
        }));
    }


    //Changes category, requires clearing area
    const handleChangeAreaOfStudyCategory = (event) => {

        const aos = profile.areasOfStudy.find(aos => aos.category_id === +event.target.value);
        setFormValues(state => ({
            ...state,
            aos_category_id: event.target.value ? +event.target.value : 1,
            primary_aos_id: 1,
            aos_category: aos?.category
        }));
        setHelperText(' ');
    }
    const handleChangeAreaOfStudy = (event) => {
        const category = profile.areasOfStudy.find(aos => aos.category_id === formValues.aos_category_id).Areas.find(category => category.id === +event.target.value);
        setFormValues(state => ({
            ...state,
            primary_aos_id: event.target.value ? +event.target.value : 1,
            primary_aos: category?.area
        }));
        setHelperText(' ');
    }

    const handleSubmit = (event) => {
        setHasSubmitted(true);

        let helperTextArray = [];
        for (let error in formValues.error) {
            if (formValues.error[error].length > 0) helperTextArray.push(formValues.error[error]);
        }

        for (let placement of formValues.placements) {
            if (placement.error.length > 0) helperTextArray.push(placement.error);
        }

        if (helperTextArray.length === 0) {
            updateOrCreateProfile(user, formValues);
        } else {
            if (helperTextArray.length === 1) setHelperText(helperTextArray[0]);
            else setHelperText(helperTextArray.reduce((acc, val) => acc + ' - ' + val));
        }


    }

    const updateOrCreateProfile = async ({ Authorization, type }, data) => {

        setIsLoaded(false);

        const uri = type === USER_TYPE.ORGANIZATION ?
            `/api/institution/person` :
            `/api/profile/data`;


        if (!newPerson) {
            await axios.put(
                uri,
                data,
                { headers: { "Authorization": Authorization } }).then(res => {
                    const d = res.json;
                    setIsLoaded(true);
                    onConfirmed();
                });
        } else {
            await axios.post(
                uri,
                data,
                { headers: { "Authorization": Authorization } }).then(res => {
                    const d = res.json;
                    setIsLoaded(true);
                    onConfirmed();
                });
        }

    }

    const handleCancelSubmit = (event) => {
        setHasSubmitted(false);
        onCancelled();
        setHelperText(' ');
    }

    const handleChangeUniversity = ({ name, id }) => {

        const error = formValues.error;
        if (id) {
            error.institution_id = '';
        } else {
            setHelperText('Please select a university');
            error.institution_id = 'University required';
        }

        setFormValues(state => ({
            ...state,
            institution_id: id,
            institution: name
        }));

    }
    const handleRemovePlacement = (idx) => {

        // Add to a list to be removed on submit
        const placement = formValues.placements.find(p => p.idx === idx);
        if (placement.id > 0) {
            formValues.formerPlacements.push(placement);
        }

        const placements = formValues.placements.filter(p => p.idx !== idx);

        setFormValues(state => ({
            ...state,
            placements: [...placements],
            formerPlacements: [...formValues.formerPlacements]
        }));

    }


    const handleAddPlacement = (event) => {
        const placements = formValues.placements;
        let maxIndex = 0;
        placements.forEach(p => { if (p.idx > maxIndex) maxIndex = p.idx; });
        placements.push({
            idx: ++maxIndex,
            id: 0,
            institution_id: 0,
            institution: '',
            placement_type_id: 1, // default to academic
            placement_type_name: null,
            position_id: 0,
            position_category: null,
            year: new Date().getFullYear(),
            error: ''
        })

        setFormValues(state => ({
            ...state,
            placements: [...placements]
        }));
        setHelperText('');
    }

    const handlePlacementChanged = (key, placementForm) => {
        const placements = formValues.placements;
        let placementIndex = placements.findIndex(p => p.idx === placementForm.idx);
        placements[placementIndex] = placementForm;
        setFormValues(state => ({
            ...state,
            placements: [...placements]
        }));
        setHelperText('');
    }

    const onChangeGradYear = (year) => {
        const error = formValues.error;
        error.graduation_year = '';
        setFormValues(state => ({
            ...state,
            graduation_year: year,
            error: error
        }));
        setHelperText('');
    }

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>

            {isLoaded && (
                <>
                    {user.type === USER_TYPE.ORGANIZATION &&
                        <div style={{ display: 'grid', gridGap: '2em', gridAutoFlow: 'column', alignItems: 'baseline' }}>

                            <FormControl margin="normal" size="small">
                                <TextField
                                    id="first-name"
                                    label="First Name"
                                    value={formValues.first_name}
                                    onChange={handleChangeFirstName}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl margin="normal" size="small">
                                <TextField
                                    id="last-name"
                                    label="Last Name"
                                    value={formValues.last_name}
                                    onChange={handleChangeLastName}
                                    required
                                    fullWidth
                                />
                            </FormControl>
                            <YearPicker error={formValues.error.graduation_year.length > 0} default={formValues.graduation_year} onChange={onChangeGradYear} includeCodings={true} label="Graduation Year" />
                        </div>


                    }

                    <div style={{ display: 'grid', gridGap: '2em' }}>
                        <div style={{ display: "grid", gridAutoFlow: "column", gridAutoColumns: "1fr", gridGap: "2em" }}>
                            <FormControl margin="normal" size="small">
                                <TextField
                                    fullWidth
                                    id="standard-basic"
                                    label="Email"
                                    value={formValues.email}
                                    onChange={handleChangeEmail}
                                />
                            </FormControl>
                        </div>
                        <div style={{ display: "grid", gridAutoFlow: "column", gridAutoColumns: "1fr", gridGap: "2em" }}>
                            <FormControl margin="normal" size="small">
                                <InputLabel shrink htmlFor="select-aos-cat">Area of Study - Category</InputLabel>
                                <NativeSelect
                                    required
                                    value={formValues.aos_category_id}
                                    onChange={handleChangeAreaOfStudyCategory}
                                    inputProps={{
                                        name: 'select-aos-cat',
                                        id: 'select-aos-cat',
                                    }}>
                                    {profile.areasOfStudy.map((val, key) => (
                                        <option key={key} value={val.category_id}>
                                            {val.category}
                                        </option>
                                    ))}
                                </NativeSelect>
                            </FormControl>
                            <FormControl margin="normal" size="small">
                                <InputLabel shrink htmlFor="select-aos-primary">Area of Study - Primary</InputLabel>
                                <NativeSelect
                                    required
                                    value={formValues.primary_aos_id}
                                    onChange={handleChangeAreaOfStudy}
                                    inputProps={{
                                        name: 'select-aos-primary',
                                        id: 'select-aos-primary',
                                    }}>
                                    {profile.areasOfStudy[formValues.aos_category_id - 1].Areas.map(
                                        (value, key) => (
                                            <option key={key} value={value.id}>
                                                {value.area}
                                            </option>
                                        )
                                    )}
                                </NativeSelect>
                            </FormControl>
                        </div>

                        {user.type === USER_TYPE.PERSON ? <div style={{ display: "grid", gridAutoFlow: "column", gridAutoColumns: "1fr", gridGap: "2em" }}>
                            <AutoUniversity
                                setHelperText={setHelperText}
                                handleUniversityChange={handleChangeUniversity}
                                formDefaults={
                                    {
                                        university: {
                                            id: formValues.institution_id,
                                            name: formValues.institution,
                                        },
                                        error: formValues.error.institution_id
                                    }
                                } />
                            <YearPicker error={formValues.error.graduation_year.length > 0} default={formValues.graduation_year} onChange={onChangeGradYear} includeCodings={true} label="Graduation year" />
                        </div> : <></>}
                        <div>
                            <Typography variant="h6">
                                <Box fontWeight="fontWeightBold">Placements</Box>
                            </Typography>
                            <div style={{ display: "grid", gridGap: "1em" }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Box fontWeight="fontWeightBold">Category</Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box fontWeight="fontWeightBold">Institution</Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box fontWeight="fontWeightBold">Type</Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box fontWeight="fontWeightBold">Year</Box>
                                                </TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {formValues.placements.map((placement, i) => (
                                                <TableRow className="placement-container" key={i}>
                                                    <PlacementEdit
                                                        index={placement.idx}
                                                        formDefaults={{ placement: formValues.placements.find(p => p.idx === placement.idx) }}
                                                        placementTypes={placementTypes}
                                                        onPlacementChanged={handlePlacementChanged}
                                                        setHelperText={setHelperText} />
                                                    <TableCell><RemovePlacementButton id={placement.idx} handleRemovePlacement={handleRemovePlacement}></RemovePlacementButton></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Button onClick={handleAddPlacement} fullWidth>Add placement</Button>
                            </div>

                        </div>
                        {newPerson &&
                            <FormControl margin="normal" size="small">
                                <TextField
                                    fullWidth
                                    id="notes"
                                    label="Notes"
                                    multiline
                                    rows={2}
                                    value={formValues.notes}
                                    onChange={handleChangeNotes}
                                />
                            </FormControl>
                        }

                        <FormHelperText error={true}>{helperText}</FormHelperText>
                        <div className="SubmitForm">
                            <Button onClick={handleCancelSubmit}>Cancel</Button>
                            <Button onClick={handleSubmit}>Save Changes</Button>
                        </div>
                    </div>

                </>
            )}
        </form>
    );
}

const RemovePlacementButton = ({ handleRemovePlacement, id }) => {
    const onRemovePlacement = () => {
        handleRemovePlacement(id)
    }
    return (<Button onClick={onRemovePlacement}><CloseIcon color="secondary" /></Button>)
}
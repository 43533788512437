import '../styles/Home.css';
import React, { useEffect } from 'react';
import icon from '../assets/icon.svg';
import {
  Link
} from "react-router-dom";
import sampleDataSrc from '../assets/SEDnew.png';

export const Home = (props) => {
  useEffect(() => {
  }, []);


  return (<div className="home">
    <div className="block">
      <div className="description">
        <p> Welcome to <b>Academic Philosophy Data & Analysis (APDA)</b>! This project collects data about philosophy PhD programs and graduates. Are you a philosophy major interested in graduate school? The chart below was created with you in mind. You can compare the employment data we have collected for different PhD programs, while also looking at survey results from past graduates and current students. Want to dive deeper into the APDA project? Check out our <a href = "https://philosophydata.org/about"> research reports</a> and <a href = "https://philosophydata.org/about"> blog posts</a>. Questions? Email <a href = "mailto: apda@ucmerced.edu">apda@ucmerced.edu</a>.</p>
      </div>
    </div>
    <div className="block">
      <div className="looker-container">
        <iframe className = "iframe-looker"
          src = "https://lookerstudio.google.com/embed/reporting/d0e72e02-3e03-4a95-b41b-a24aa6524529/page/6zXD"
          allowFullscreen
        ></iframe>
      </div>
    </div>

    <h1>Where to begin</h1>
    <div className="block centered">
      <Link to="/data" className="link">Data</Link>
      <div className="description">Explore an anonymized version of the APDA dataset.</div>
    </div>
    <div className="block centered">
      <Link to="/accounts" className="link">Accounts</Link>
      <div className="description">Get information on how to access your personal/organizational account</div>
    </div>
    <div className="block centered">
      <Link to="/about" className="link">About</Link>
      <div className="description">Learn more about APDA</div>
    </div>
    <div className="block centered">
      <Link to="/blog" className="link">Blog</Link>
      <div className="description">See previous research and writeups from ADPA</div>
    </div>
  </div>)
}

export default Home;
import React, { useState, useEffect } from "react";
import Tooltip from '@material-ui/core/Tooltip';
import axios from "axios";
import { CircularProgress } from '@material-ui/core';


export const GraduationData = ({ institution }) => {

    const [graduateData, setGraduateData] = useState({});
    const [hasLoaded, setHasLoaded] = useState(false); // track whether this has loaded at least once


    useEffect(() => {
        if (institution?.id) {
            getGraduates(institution.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [institution])

    const getGraduates = async (institution_id) => {
        if (!loading) setLoading(true);

        await axios.get(
            `/api/graduates/${institution_id}`,
            {}).then(res => {
                let data = res.data;
                if (data) {
                    // group by year, then sort in descending order

                    const groupedMap = data.reduce(
                        (entryMap, e) => entryMap.set(e.graduation_year, [...entryMap.get(e.graduation_year) || [], e]),
                        new Map()
                    );

                    // remove unknown, etc. dates from array we display
                    groupedMap.forEach((value, key) => {
                        if (key <= 100) {
                            groupedMap.delete(key);
                        }
                    })

                    data = Array.from(groupedMap.entries());
                    data = data.sort((a, b) => { return b[0] - a[0] });


                    setGraduateData(data);
                } else {
                    setGraduateData(null); // No records for the selected university
                }
                if (!hasLoaded) setHasLoaded(true)
                setLoading(false);
            });
    }

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    return (
        <div>
            {institution?.id ?
                <div className="university">
                    <div className="name">{institution.name}</div>
                    {graduateData && graduateData.length > 0 ?
                        graduateData.map((recordsForYear, j) => (
                            <div key={j}>
                                <div className="header">
                                    <div className="year">{recordsForYear[0]} Graduates</div>
                                </div>
                                <div className="table">
                                    <div className="head">
                                        <div className="row">
                                            <div className="cell">Area of Specialization</div>
                                            <div className="cell">Placements</div>
                                            
                                        </div>
                                    </div>
                                </div>

                                {recordsForYear[1].map((graduate, key) => (

                                    <div className="graduate" key={key}>

                                        <div className="graduate table" aria-label="profile table">
                                            <div className="body">
                                                <div className="row">
                                                    <div className="cell"><Tooltip title="Area of Specialization" arrow><span>{graduate.aos_category}: {graduate.primary_aos}</span></Tooltip></div>
                                                    <div className="cell">

                                                        <div className="table">

                                                            <div className="body">
                                                                {/* <div className="row placements">Placements</div> */}
                                                                {graduate.placements && graduate.placements.map((placement, k) => (
                                                                    <div className="row" key={k}>
                                                                        <div className="cell" ><Tooltip title="Year" arrow><span>{placement.year > 5 ? placement.year : ''}</span></Tooltip></div>
                                                                        <div className="cell" ><Tooltip title="Institution" arrow><span>{placement.institution}</span></Tooltip></div>
                                                                        <div className="cell" ><Tooltip title="Category" arrow><span>{placement.position_category}</span></Tooltip></div>
                                                                    </div>
                                                                ))
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                ))
                                }
                            </div>
                        ))



                        : !loading ? <>
                            {error && <div>{error}</div>}
                            {hasLoaded ? <div>No records for this institution</div> : <div>Search for a university</div>}
                        </>
                            : <CircularProgress />}


                </div> :
                <div className="no-university">Search for a university</div>
            }
        </div>
    )
}
export default GraduationData;
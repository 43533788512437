
import '../styles/Profile.css';
import React, { useState, useEffect } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import { AutoUniversity } from "../components/AutoUniversity";
import { YearPicker } from "../components/YearPicker";
import TableCell from "@material-ui/core/TableCell";
import TextField from '@material-ui/core/TextField';


export const PlacementEdit = ({ placementTypes, index, onPlacementChanged, formDefaults, setHelperText }) => {
    const [placementForm, setPlacementForm] = useState(null);
    // default to academic
    const [isAcademic, setIsAcademic] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);

    const initialize = () => {

        const initForm = { ...formDefaults.placement };

        initForm.placement_type_id = initForm.placement_type_id ? initForm.placement_type_id : placementTypes[0].placement_type_id;
        initForm.placement_type_name = initForm.placement_type_name ? initForm.placement_type_name : placementTypes[0].placement_type_name;
        initForm.position_category = initForm.position_category ? initForm.position_category : placementTypes[0].positions[0].position_category;
        initForm.position_id = initForm.position_id ? initForm.position_id : placementTypes[0].positions[0].position_id;
        initForm.error = initForm.placement_type_id === 1 && !initForm.institution_id ? 'Please select an institution for every academic placement' : '';
        setIsAcademic(initForm.placement_type_id === 1 ? true : false);
        setPlacementForm(initForm);
        setIsInitialized(true);
        
    }

    useEffect(() => {

        initialize();

    }, [index])


    useEffect(() => {
        if (isInitialized) onPlacementChanged(index, placementForm);
    }, [placementForm])

    const handleChangeType = (event) => {
        const newType = placementTypes.find(t => t.placement_type_id === +event.target.value);

        // 1 == academic
        if (newType.placement_type_id === 1) {
            setIsAcademic(true);
            placementForm.error = 'Please enter a university';
            setHelperText('Please select an institution for every academic placement');
            // load uni list
        }

        // 3 == non-academic
        if (newType.placement_type_id === 3) {
            //load a freeform text box
            setIsAcademic(false);
            placementForm.error = '';
            setHelperText('');
            placementForm.institution = '';
            placementForm.institution_id = 0;
        }

        // set defaults to first in list
        setPlacementForm(state => ({
            ...state,
            placement_type_id: newType.placement_type_id,
            placement_type_name: newType.placement_type_name,
            position_category: newType.positions[0].position_category,
            position_id: newType.positions[0].position_id,
            institution_id: placementForm.institution_id,
            institution: placementForm.institution,
            error: placementForm.error
        }));
    }

    const handleChangePosition = (event) => {
        const newPositionId = +event.target.value;

        let position_category = "";
        for (let placementType of placementTypes) {
            for (let position of placementType.positions) {
                if (position.position_id === newPositionId) {
                    position_category = position.position_category;
                }
            }
        }

        setPlacementForm(state => ({
            ...state,
            position_category: position_category,
            position_id: newPositionId,
        }));
    }

    const handleChangeYear = (updatedYear) => {
        setPlacementForm(state => ({
            ...state,
            year: updatedYear,
        }));
    }

    const handleChangeInstitution = (event) => {
        const institution = event.target.value;
        setPlacementForm(state => ({
            ...state,
            institution_id: 0,
            institution: institution,
        }));

    }

    const handleChangeUniversity = ({ name, id }) => {
        if (!id) {
            placementForm.error = 'Please enter a university';
            // placementForm.position_category = null;
            // placementForm.position_id = null;
            setHelperText('Please select an institution for every academic placement');
        } else placementForm.error = '';

        setPlacementForm(state => ({
            ...state,
            institution_id: id,
            institution: name,
            // position_category: placementForm.position_category,
            // position_id: placementForm.position_id,
            error: placementForm.error
        }));

    }

    return (
        isInitialized &&
        <>
            <TableCell>
                <NativeSelect
                    required
                    value={placementForm.placement_type_id}
                    onChange={handleChangeType}
                    inputProps={{
                        name: 'select-placement-type',
                        id: 'select-placement-type',
                    }}>
                    {placementTypes.map((v, k) => (
                        <option key={k} value={v.placement_type_id}>
                            {v.placement_type_name}
                        </option>
                    ))}
                </NativeSelect>
            </TableCell>
            <TableCell>
                {isAcademic ?
                    <AutoUniversity
                        setHelperText={setHelperText}
                        handleUniversityChange={handleChangeUniversity}
                        formDefaults={
                            {
                                university: {
                                    id: placementForm.institution_id,
                                    name: placementForm.institution
                                },
                                error: '' // TODO: Deeper, more specific errors
                            }
                        } /> :
                    <TextField label="Institution" variant="outlined" value={placementForm.institution} onChange={handleChangeInstitution} />
                }

            </TableCell>

            <TableCell>
                <NativeSelect
                    required
                    value={placementForm.position_id}
                    onChange={handleChangePosition}
                    inputProps={{
                        name: 'select-position',
                        id: 'select-position',
                    }}>

                    {placementTypes.find(pt => pt.placement_type_id === placementForm.placement_type_id).positions.map((position, k) =>
                    (
                        <option key={k} value={position.position_id}>
                            {position.position_category}
                        </option>
                    )
                    )}
                </NativeSelect>
                {/* <div>
                <div>Actual: {placementForm.position_id}</div>
                {placementTypes.find(pt => pt.placement_type_id === placementForm.placement_type_id).positions.map((position, k) =>
                    (
                            <div>{position.position_id}: {position.position_category}</div>
                    )
                    )}
                </div> */}
            </TableCell>
            <TableCell>
                {
                    <YearPicker default={placementForm.year} onChange={handleChangeYear} includeCodings={false} label="Year started" />
                }
            </TableCell>
        </>




    )
}
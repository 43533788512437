import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from '@material-ui/core/CircularProgress';

export const AutoUniversity = ({formDefaults, handleUniversityChange, setHelperText}) => {

  const [open, setOpen] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [value, setValue] = useState(formDefaults.university ? formDefaults.university : null);
  const [error, setError] = useState(formDefaults.error);
  const loading = open && universities.length === 0;

  useEffect(() => {
    if (value && value.id !== formDefaults.university?.id) {
      handleUniversityChange({id: value?.id, name: value?.name});
    }
  }, [value])

  useEffect(() => {

    if (!loading) {
      return undefined;
    }
    
    let active = true;

    (async () => {
      const response = await fetch(`/api/unis`); 
      const institutions = await response.json();

      if (active) {
        setUniversities(institutions);
      }
    })();

    return () => {
      active = false;
    };
    
  }, [loading])


  const onChange = (_, newValue) => {
    setValue(newValue ? 
      { name: newValue.name, id: newValue.id }: 
      { name: '', id: 0 });

    if (!newValue) { 
      setHelperText(`${value} not found`); 
    } else setHelperText(''); 
  }

  const onRenderInput = (params) => {
    return (
      <TextField
      {...params}
      error={error.length > 0}
      label="University"
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />)
  }

  return (
      <Autocomplete
        id="combo-box-demo"
        value={value}
        options={universities}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        loading={loading}
        getOptionSelected={(option, value) => option && option.name === value.name }
        getOptionLabel={(option) => option.name ? option.name : ''}
        //onInputChange={onInputChange}
        onChange={onChange}
        renderInput={onRenderInput}
      />
  );
};

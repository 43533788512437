import '../styles/Data.css';
import GraduationData from '../components/GraduationData';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";

import { TableFooter, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Grid, Typography } from '@material-ui/core';

//import { UniRow } from '../components/UniRow'

import { AutoUniversity } from "../components/AutoUniversity";

const useStyles = makeStyles({
    root: {
        fontWeight: 600
    }
});
export const Data = () => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { institutionId } = useParams();
    const params = useParams();
    const [institution, setInstitution] = useState({ name: '', id: null });
    const [helperText, setHelperText] = useState('Select a university name to begin');
    const navigate = useNavigate();

    useEffect(() => {
        if (institutionId) {
            getInstitution(institutionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInstitution = async (institution_id) => {
        setLoading(true);
        await axios.get(
            `/api/uni/${institution_id}`,
            {}).then(res => {
                let data = res.data;
                if (data) {
                    setInstitution({ name: data.name, id: institution_id });
                    if (error) setError('');
                } else {
                    setError('Could not find university with id: ' + institution_id + '- Please try searching by name instead');
                }
                setLoading(false);
            });
    }

    const classes = useStyles();

    const handleChangeInstitution = ({ name, id }) => {

        if (name) {
            if (error) setError('');
            const institution = { name: name, id: id };
            setInstitution(institution);
            navigate(`/data/${id}`, { institution: institution });
        } else {
            setInstitution({ name: '', id: null });
        }

    }

    return (
        <div className="data">
            <AutoUniversity
                handleUniversityChange={handleChangeInstitution}
                setHelperText={setHelperText}
                formDefaults={
                    {
                        university: {
                            id: institution?.id ? institution.id : null,
                            name: '',
                        },
                        error: false
                    }
                }
            />

            <Routes>
                <Route
                    path=":institutionId"
                    element={<GraduationData institution={institution} />} />
            </Routes>

        </div>
    );

}

export default Data;
import '../styles/Error.css';
import React from 'react';

export const Error = (props) => {
    return (<div className="error">
        <h1>An error occurred</h1>
        <div className="block two-col">
            <div>
                <p>If you were trying to access your account, please try again.</p>
                <p>Otherwise, please use the navigation bar above to find what you're looking for.</p>
            </div>
            <div className="contact">Contact email: <a href="mailto:apda@ucmerced.edu">apda@ucmerced.edu</a></div>
        </div>
        
        </div>);
}

export default Error;
import '../styles/InstitutionSummary.css';
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PersonAdd from "@material-ui/icons/PersonAdd";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

import { ProfileEdit } from "../components/ProfileEdit";
import { UserContext } from "../UserContext";
import { ProfileSummary } from "./ProfileSummary";

export const InstitutionSummary = ({ user }) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [newPerson, setNewPerson] = useState(null);

  useEffect(() => {
    if (!data) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    if (!loading) setLoading(true);
    await axios.get(`/api/institution/persons`,
      { headers: { "Authorization": user.Authorization } }).then(res => {
        setData(res.data);
        setLoading(false);
      });
  }


  const onClickAdd = () => {
    setNewPerson({
      id: 0,
      institution: data[0].institution,
      institution_id: data[0].institution_id,
      aos_category_id: 1, // unknown
      primary_aos_id: 1, // unknown
      placements: [],
      graduation_year: new Date().getFullYear()
    });
  }

  const onCancelledAdd = () => {
    setNewPerson(null);
  }


  const onConfirmedAdd = () => {
    setNewPerson(null);
    getData();
  }

  return (
    <div className="view">
      {!loading ? <>
        {!newPerson && <Card>
          <CardHeader
            action={
              <>
                <div>Add graduation record</div>
                <IconButton
                  aria-label="settings"
                  onClick={onClickAdd}>
                  <PersonAdd />
                </IconButton>
              </>

            }
            title={'Existing data for ' + data[0].institution}
            subheader={
              <Typography variant="subtitle2">
                Please review your institutional data and correct any errors or omissions.
              </Typography>
            }
          />

        </Card>
        }
        {
          newPerson &&
          <Card>
            <CardHeader

              title="Add Graduate Record"
              subheader={
                <Typography variant="subtitle2">
                  Fill out as much information as possible about the missing graduate record and submit when done. <em>(Make sure this person hasn't already been added before you do this!)</em>
                </Typography>
              }
            />
            <CardContent>
              <ProfileEdit person={newPerson} user={user} onCancelled={onCancelledAdd} onConfirmed={onConfirmedAdd} />
            </CardContent>
          </Card>

        }
        {
          data && data.map(person =>
            <div className="summary-container" key={person.id}>
              <ProfileSummary person={person} condensed={true} user={user} />
            </div>
          )
        }
      </> : <CircularProgress />}
    </div>

  );
};

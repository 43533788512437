import '../styles/Accounts.css';
import React from 'react';

export const Accounts = (props) => {
    return (<div className="accounts">
        <h1>Looking for your account?</h1>
        <div className="block two-col">
            <div>
                <p>If you've accessed your account before, look for the survey email we sent you. (Search for <em>apda@ucmerced.edu</em> in your mailboxes)</p>
                <p>If this is the first time you are accessing your personal or organizational account or if you've simply lost your credentials, please contact us and we will send you a link that will serve as a login to APDA.</p>
            </div>
            <div className="contact">Contact email: <a href="mailto:apda@ucmerced.edu">apda@ucmerced.edu</a></div>
        </div>
        
        </div>);
}

export default Accounts;
import '../styles/Profile.css';
import '../styles/View.css';
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { ProfileEdit } from "../components/ProfileEdit";
import { UserContext } from "../UserContext";
import { USER_TYPE } from "../models/models"

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    fontWeight: 600
  }
});

export const ProfileSummary = ({ user, condensed, person }) => {

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(!person ? true : false);
  const [data, setData] = useState(person ? person : null);

  useEffect(() => {
    if (!data) {
      getData();
    } else {
      if (!data.placements) data.placements = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onCancelled = () => {
    setEditing(false);
  }


  const getData = async () => {
    const uri = (user.type === USER_TYPE.ORGANIZATION) ? 
        `/api/institution/person/${person.id}`:
        `/api/profile/data`;
    await axios.get(
      uri,
      { headers: { "Authorization": user.Authorization } }).then(res => {
        if (!res.data.placements) res.data.placements = [];
        setData(res.data);
        setLoading(false);
      });
  }

  const onConfirmed = async () => {
    setEditing(false);
    setLoading(true);
    getData();
  }

  const onSetEditing = () => {
    setEditing(true);
  }

  const getYearLabel = (year) => {
    let label = '';
    if (year === 0) return 'Unknown Year';
    if (year === 1) return 'Expected';
    if (year === 2) return 'Current Student';
    console.error('Invalid year: ' + year);
    return label;
  }

  const classes = useStyles();

  return (
    <div className="view">
      {loading ? <CircularProgress /> :
        <>
          {!condensed ? <><Typography align="center" variant="h4">
            Welcome, {data.first_name} {data.last_name}
          </Typography>
            <Card>
              <CardHeader
                action={
                  !editing && <IconButton
                    aria-label="settings"
                    onClick={onSetEditing}>
                    <EditIcon />
                  </IconButton>
                }
                title="Data Review"
                subheader={
                  <Typography variant="subtitle2">
                    Please review your data and correct any errors or omissions.
                    This is what we currently have on file:
                  </Typography>
                }
              />
              <CardContent>
                {
                  editing ?
                    <ProfileEdit person={data} user={user} onCancelled={onCancelled} onConfirmed={onConfirmed} /> :
                    <>
                      <Typography variant="h6">
                        <strong>Email: </strong>
                        {data.email}
                      </Typography>
                      <Typography variant="h6">
                        <strong>Area of Study: </strong>
                        {data.primary_aos ? 
                        <>{data.primary_aos} <strong>|</strong> {data.aos_category}</>:
                        <span>Unknown</span>}
                      </Typography>
                      <Typography variant="h6">
                        <strong>Graduation: </strong>
                        {data.institution ? data.institution : <>Unknown Institution</>} <strong> | </strong> 
                        {data.graduation_year > 10 ? 
                          data.graduation_year : getYearLabel(data.graduation_year)
                        }
                      </Typography>
                      {data.placements && <Typography variant="h6">
                        <strong fontWeight="fontWeightBold">Placements: </strong>
                        {data.placements && data.placements.length > 0 ? data.placements.map((placement, key) => (
                          <div key={key}>
                          &emsp;{placement.institution} <strong>|</strong> <em>{placement.placement_type_name + ' - ' + placement.position_category}</em> <strong>|</strong> {placement.year}
                          </div>
                          
                        )): <>None</>} 
                      </Typography>}
                    </>
                }

              </CardContent>
            </Card></> :
            <Card>
              <CardHeader
                action={
                  !editing && <IconButton
                    aria-label="settings"
                    onClick={onSetEditing}>
                    <EditIcon />
                  </IconButton>
                }
                title={`${data.first_name} ${data.last_name}`}
              />
              <CardContent>
                {editing ?
                  <ProfileEdit person={data} user={user} onCancelled={onCancelled} onConfirmed={onConfirmed} /> :
                  <TableContainer>
                    <Table className="condensed table" aria-label="profile table" size="small" padding="none">
                      <TableHead>
                        <TableRow>
                          <TableCell classes={{ root: classes.root }}>Graduated</TableCell>
                          <TableCell classes={{ root: classes.root }}>Area of Specialization</TableCell>
                          <TableCell classes={{ root: classes.root }}>Email Address</TableCell>
                          <TableCell classes={{ root: classes.root }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{data.graduation_year === 2 ? "Current Student" : data.graduation_year}</TableCell>
                          <TableCell>{data.aos_category}: {data.primary_aos}</TableCell>
                          <TableCell>{data.email}</TableCell>
                          <TableCell>

                            <Table size="small" padding="none">
                              <TableHead>
                                <TableRow>
                                  <TableCell classes={{ root: classes.root }}>Placements</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>

                                {data.placements && data.placements.map((placement, key) => (
                                  <TableRow key={key}>
                                    <TableCell>{placement.placement_type}</TableCell>
                                    <TableCell>{placement.year}</TableCell>
                                    <TableCell>{placement.institution}</TableCell>
                                    <TableCell>{placement.title}</TableCell>
                                  </TableRow>
                                ))}

                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow> 
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </CardContent>
            </Card>

          }
        </>
      }
    </div>
  )
}
